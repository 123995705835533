<template>
  <div id="page-member-registrations">

    <MemberEditorCompactModal

      ref="member-editor-modal"

      class="mt-8"
      :member_id="member_id"

      @created="member_created"
      @updated="member_updated"
      @deleted="member_deleted"
    />

    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab @click.prevent="refresh_table">{{ $t('PAGES.MEMBER_REGISTRATIONS.REQUESTS') }}</v-tab>
        <v-tab @click.prevent="refresh_table">{{ $t('PAGES.MEMBER_REGISTRATIONS.HISTORY') }}</v-tab>
        <v-tab @click.prevent="refresh_table">{{ $t('PAGES.MEMBER_REGISTRATIONS.EXITS') }}</v-tab>
        <v-tab @click.prevent="refresh_table_exit_history">{{ $t('PAGES.MEMBER_REGISTRATIONS.EXITS_HISTORY') }}</v-tab>
        <v-tab>{{ $t('COMMON.SETTINGS') }}</v-tab>

        <v-tab-item>

          <MemberRegistrationRequestsTable
            class="mt-8"
            :items="pending_requests"
            @member_clicked="member_clicked"
            @member_status_changed="member_status_changed"
          />

        </v-tab-item>

        <v-tab-item>

          <MemberRegistrationHistoryTable
            class="mt-8"
            @select_member_clicked="member_clicked"

          />

        </v-tab-item>

        <v-tab-item>

          <MemberExitRequestTable
            class="mt-8"
            :items="pending_exit_requests"
            @member_clicked="member_clicked"
            @exit_success="exit_success"
            @status_updated="status_updated"
          />

        </v-tab-item>

        <v-tab-item>

          <MemberExitHistoryTable
            ref="member-exit-history-table"
            class="mt-8"
            @select_member_clicked="member_clicked"

          />

        </v-tab-item>

        <v-tab-item>
          <MemberRegistrationsSettings
            class="mt-8"
          />

        </v-tab-item>

      </v-tabs>

    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import MemberRegistrationsSettings from '@/view/pages/ml/member_registrations/MemberRegistrationsSettings.vue';
import MemberRegistrationRequestsTable from '@/view/pages/ml/member_registrations/MemberRegistrationRequestsTable.vue';
import MemberRegistrationHistoryTable from '@/view/pages/ml/member_registrations/MemberRegistrationHistoryTable.vue';
import MemberExitHistoryTable from '@/view/pages/ml/member_registrations/MemberExitHistoryTable.vue';
import MemberExitRequestTable from '@/view/pages/ml/member_registrations/MemberExitRequestTable.vue';
import MemberEditorCompactModal from '@/view/components/member_editor_v2/MemberEditorCompactModal.vue';



export default {
  name: 'member-registrations',
  components: {
    MemberRegistrationRequestsTable,
    MemberRegistrationsSettings,
    MemberRegistrationHistoryTable,
    MemberEditorCompactModal,
    MemberExitHistoryTable,
    MemberExitRequestTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings'])
  },
  mounted() {
    this.load_members('PENDING');
    this.load_members('PENDING_EXIT');
  },
  data() {
    return {
      pending_requests: [],
      pending_exit_requests: [],

      member_id: null
    };
  },
  watch: {

  },
  methods: {
    exit_success(member_id) {
      this.load_members('PENDING_EXIT');
    },

    status_updated(member_id, status) {
      this.load_members('PENDING_EXIT');
    },

    member_created(member) {},
    member_updated(member) {},
    member_deleted(member) {},

    member_pending_status_changed(member) {
      const index = this.pending_exit_requests.findIndex(x => x.member_id === member.member_id);

      if (index !== -1) {
        // remove it from the pending requests
        this.pending_exit_requests.splice(index, 1);
      }
    },

    member_status_changed(member) {
      const index = this.pending_requests.findIndex(x => x.member_id === member.member_id);

      if (index !== -1) {
        // remove it from the pending requests
        this.pending_requests.splice(index, 1);
      }

      this.member_pending_status_changed(member);
    },

    member_clicked(member_id) {
      this.member_id = member_id;
      
      this.$refs['member-editor-modal'].show(member_id);
    },

    refresh_table() {
      this.load_members('PENDING');
      this.load_members('PENDING_EXIT');
    },

    refresh_table_exit_history() {
      this.$nextTick(()=>{
        
        setTimeout(()=>{
          this.$refs['member-exit-history-table'].refresh();
        },1000);
        
      });
    },

    async load_members(status) {
      try {
        const loader = this.$loading.show();

        const res = await axios.get(`/member/status/${status}`);

        if (res.status !== 200) {
          loader && loader.hide();

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_LIST'));
          return;
        }

        if (status === 'PENDING') {
          this.pending_requests = res.data;
        }
        else if (status === 'PENDING_EXIT') {
          this.pending_exit_requests = res.data;
        }

        loader && loader.hide();
      } catch (error) {
        console.log(error);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_LIST'));
      }

    }

  }
};
</script>
