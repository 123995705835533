<template>
  <div>

    <Confirm
      :title="$t('PAGES.MEMBER_REGISTRATIONS.CONFIRM_ACCEPT')"
      :message="$t('PAGES.MEMBER_REGISTRATIONS.CONFIRM_ACCEPT_MESSAGE')"
      ref="confirm-accept"
      @cancel="cancel_accept"
      @confirm="confirm_accept"
    />

    <Confirm
      :title="$t('PAGES.MEMBER_REGISTRATIONS.CONFIRM_REJECT')"
      :message="$t('PAGES.MEMBER_REGISTRATIONS.CONFIRM_REJECT_MESSAGE')"
      ref="confirm-reject"
      @cancel="cancel_reject"
      @confirm="confirm_reject"
    />

    <b-table
      id="member-registrations-table"
      class="table-striped mh-100"
      :fields="headers"
      :items="items"
      head-variant="light"
      sticky-header
      responsive
      striped
      hover
    >

      <template #cell(actions)="row">

        <div class="justify-content-end d-flex">

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="reject_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-times-circle color-primary"></i>
            </span>
          </a>

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="confirm_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-check-circle color-primary"></i>
            </span>
          </a>

          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>

        </div>
      </template>

    </b-table>

    <div v-if="items.length === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<style lang="scss" scoped>
.pagination-select {
  height: 32px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 12px;
  max-width: 100px;
}

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import Confirm from '@/view/components/Confirm.vue';

export default {
  name: 'MemberRegistrationRequestsTable',
  mixins: [ toasts ],
  props: ["items","show_create"],
  emits: ['member_status_changed', 'member_clicked', 'skv_job_finished'],
  components: {
    Confirm
  },
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId','settings']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }

    if (!this.settings.is_faith) {
      this.headers = this.headers.filter(h => h.key !== 'skv_period');
    }
  },
  methods: {

    reject_member_clicked(member_id) {
      this.selected_member_id = member_id;
      this.$refs['confirm-reject'].show();
    },

    confirm_member_clicked(member_id) {
      this.selected_member_id = member_id;
      this.$refs['confirm-accept'].show();
    },

    cancel_accept() {},

    confirm_accept() {
      this.put_member_status(this.selected_member_id, 'MEMBER');
    },

    cancel_reject() {},

    confirm_reject() {
      this.put_member_status(this.selected_member_id, 'REJECTED');
    },

    async put_member_status(member_id, status) {
      try {
        const loader = this.$loading.show();

        const res = await axios.put(`/member/status/${member_id}`, { status });

        if (res.status !== 200) {
          loader && loader.hide();

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_UPDATE'));

          return;
        }

        loader && loader.hide();

        this.$emit('member_status_changed', res.data);
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_UPDATE'));
      }
    },

    async run_skv_job() {
      try {

        this.toastr('danger', this.$t('COMMON.ERROR'), 'REMOVE THIS');

        const loader = this.$loading.show();

        const res = await axios.post(`/skv/export/member/registration`, {});

        if (res.status !== 201) {
          loader && loader.hide();

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.ERROR_RUNNING_JOB'));

          return;
        }

        this.$emit('skv_job_finished', res.data);
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.ERROR_RUNNING_JOB'));
      }


    },

    select_member_clicked(member_id) {
      this.$emit('member_clicked', member_id);
    },


  },
  data() {
    return {

      selected_member_id: null,

      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [

        {
          key: 'public_id',
          label: this.$t('MEMBER.PUBLIC_ID_SHORT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'firstname',
          label: this.$t('MEMBER.FIRSTNAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'lastname',
          label: this.$t('MEMBER.LASTNAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'fmt_personnr',
          label: this.$t('MEMBER.PERSONNR'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'accepted_at',
          label: this.$t('PAGES.MEMBER_JOBS.ACCEPTED_AT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'skv_period',
          label: this.$t('SKV.SKV_PERIOD'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle',
          formatter: (_, __, item) => {
            if (item.skv_period) {
              return item.skv_period.name;
            }

            return '-';
          },
        },
        {
          key: 'actions',
          label: ' ',
          thClass: 'w-120px text-right pr-12 align-middle',
        },

      ],

    };
  }
};
</script>
