<template>
  <tr>
    <td>{{ item.descr }}</td>
    <td>
        <b-form-select
          v-model="local_item.template_id"
          :options="templateOptions"
          @change="templateRowUpdated"
        ></b-form-select>
    </td>
    <td></td>
  </tr>
</template>
<style lang="scss" scoped>
.descr {
  font-size: 11px;
}
</style>
<script>
import axios from 'axios';

export default {
  name: "systems-table-row",
  props: ["templateOptions","item"],
  emits: ['templateUpdated'],
  components: {

  },
  computed: {

  },
  watch: {
    item: {
      handler(val) {
        this.local_item = { ...val };
      },
      deep: true
    }
  },
  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }
  },
  methods: {
    templateRowUpdated() {
      this.$emit('templateUpdated', this.local_item.id, this.local_item.template_id);
    },

  },

  data() {
    return {
      local_item: {},
    };
  }
};
</script>
