<template>
  <div>

    <div class="p-8">
      <b-row>
        <b-col>
          <tip-label :tip="$t('PAGES.MEMBER_REGISTRATIONS.TIP_REG_APPROVAL')">
            <h5 class="mb-4 mt-4">{{$t('PAGES.MEMBER_REGISTRATIONS.HEADER_REG_APPROVAL')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="8">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-reg_approval" type="checkbox" name="" v-model="reg_approval"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('PAGES.MEMBER_REGISTRATIONS.CHECKBOX_REG_APPROVAL') }}</span>
          </div>

        </b-col>
      </b-row>
    </div>

    <div class="p-8">
      <b-row>
        <b-col>
          <tip-label :tip="$t('PAGES.MEMBER_REGISTRATIONS.TIP_EXIT_APPROVAL')">
            <h5 class="mb-4 mt-4">{{$t('PAGES.MEMBER_REGISTRATIONS.HEADER_EXIT_APPROVAL')}}</h5>
          </tip-label>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="8">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-outline checkbox-success">
              <input id="field-exit_approval" type="checkbox" name="" v-model="exit_approval"/>
              <span></span>
            </label>
            <span class="ml-3">{{ $t('PAGES.MEMBER_REGISTRATIONS.CHECKBOX_EXIT_APPROVAL') }}</span>
          </div>

        </b-col>
      </b-row>
    </div>


    <b-row>
      <b-col cols="12">
        <SystemTable
          :title="$t('PAGES.MEMBER_REGISTRATIONS.APPLICATION_TEMPLATES')"
          :items="application_templates_array"
          :templateOptions="template_options"
          @templateUpdated="template_updated"
        />
      </b-col>
    </b-row>

    <div class="p-8">

      <b-row class="mt-8">
        <b-col md="8">
          <b-button type="submit" variant="primary" @click.prevent="save_data">{{ $t('COMMON.SAVE') }}</b-button>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import TipLabel from '@/view/components/TipLabel.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import ColorPickerInput from '@/view/components/ColorPickerInput';
import SystemTable from '@/view/pages/ml/system/SystemTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {

  name: 'MemberRegistrationSettings',

  components: {
    TipLabel,
    RequiredFieldsInfo,
    ColorPickerInput,
    SystemTable
  },

  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentCompanyId']),

    application_templates_array() {
      return Object.values(this.application_templates);
    }

  },

  watch: {

  },

  methods: {

    async saveSystem() {
      var data = {
        member_application_pending_template_id: this.application_templates.member_application_pending_template_id.template_id,
        member_approved_email_template_id: this.application_templates.member_approved_email_template_id.template_id,
        member_rejected_email_template_id: this.application_templates.member_rejected_email_template_id.template_id,
        admin_exit_requested_template_id: this.application_templates.admin_exit_requested_template_id.template_id,
      };

      axios
        .put('/system', data)
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SYSTEM.UPDATED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SYSTEM.UNABLE_UPDATE'));
        });
    },

    async template_updated(id, template_id) {
      this.application_templates[id].template_id = template_id;
      // Vue3 compatability for Vue.set
      this.application_templates = { ...this.application_templates };
    },

    async load_templates() {
      this.templates = [];

      try {
        const result = await axios.get(`/template?company_id=${this.currentCompanyId}`);

        if (result.status === 200) {
          this.templates = result.data;

          this.template_options.push({
            value: null,
            text: 'Ingen'
          });

          for (var i = 0; i < this.templates.length; ++i) {
            this.template_options.push({
              value: this.templates[i].template_id,
              text: this.templates[i].name
            });
          }
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
      }

    },

    async load_settings() {
      try {
        const response = await axios.get(`/company/${this.currentCompanyId}`);
        this.reg_approval = response.data.company_settings.reg_approval;
        this.exit_approval = response.data.company_settings.exit_approval;

      } catch (error) {
        this.toastr('danger', this.$t('COMMON.ERROR'), error);
      }
    },

    async save_data() {

      try {

        let data = {
          reg_approval: this.reg_approval,
          exit_approval: this.exit_approval,
        }

        const response = await axios.put(`/company/company_settings/${this.currentCompanyId}`, data);

        if (response.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        }

        this.saveSystem();
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
      }
    },



    loadData() {
      axios
        .get(`/system`)
        .then(res => {
          var sys = res.data;

          for (const k in this.application_templates) {
            this.application_templates[k].template_id = sys[k];
          }

          this.application_templates = { ...this.application_templates };
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_REGISTRATIONS.UNABLE_GET_SYSTEM_SETTINGS'));
        });
    },
  },

  async mounted() {
    await this.loadData();
    await this.load_templates();
    await this.load_settings();
  },

  data() {
    return {
      templates: [],
      template_options: [],

      application_templates: {
        member_application_pending_template_id: {
          id: 'member_application_pending_template_id',
          descr:  this.$t('SYSTEM.TEMPLATE.MEMBER_APPLICATION_PENDING_TEMPLATE_ID'),
          template_id: ''
        },
        member_approved_email_template_id: {
          id: 'member_approved_email_template_id',
          descr:  this.$t('SYSTEM.TEMPLATE.MEMBER_APPROVED_EMAIL_TEMPLATE_ID'),
          template_id: ''
        },
        member_rejected_email_template_id: {
          id: 'member_rejected_email_template_id',
          descr:  this.$t('SYSTEM.TEMPLATE.MEMBER_REJECTED_EMAIL_TEMPLATE_ID'),
          template_id: ''
        },
        admin_exit_requested_template_id: {
          id: 'admin_exit_requested_template_id',
          descr:  this.$t('SYSTEM.TEMPLATE.ADMIN_EXIT_REQUESTED_TEMPLATE_ID'),
          template_id: ''
        }
      },


      reg_approval: false,

      exit_approval: false
    };
  }

};

</script>
